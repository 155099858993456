import React from 'react';
import { ReactComponent as IconUndss } from '../../assets/svg/logo_undss.svg';
import { ReactComponent as IconClearHouse } from '../../assets/svg/icon_clearing_house.svg';
import './styles.scss';

export function Logo() {
  return (
    <div className='logo'>
      <IconUndss />
      <div className='text'>
        <div className='row-top'>
          <label>
            <span className='thin'>easy</span>
            <span>sync</span>
          </label>
          <IconClearHouse className='icon' />
        </div>
        <div className='row-bottom'>control panel</div>
      </div>
    </div>
  );
}
