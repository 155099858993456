import React from 'react';
import { Logo } from '../../components';
import { Button } from 'antd';
import './styles.scss';

export const NotFound: React.FC = () => {
  const goToMainPage = (): void => {
    window.location.href = '/';
  };

  return (
    <section className='container not-found'>
      <div className='panel'>
        <div className='row'>
          <Logo />
        </div>
        <div className='row'>
          <span className='message'>The Page you are looking for doesn&apos;t exist</span>
        </div>
        <div className='row'>
          <Button size='large' type='primary' onClick={goToMainPage}>
            Go to main page
          </Button>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
